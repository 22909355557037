const dicts = {
    // 是/否
    YES_OR_NOT: {
        "1": "是",
        "0": "否",
        "Y": "是",
        "N": "否"
    },

    // 启用/禁用
    ENABLE_OR_NOT: {
        "1": "已启用",
        "0": "已禁用",
        "Y": "已启用",
        "N": "已禁用"
    },
};

export default dicts;