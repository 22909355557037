import Vue from "vue";
import App from "./App.vue";
import lodash from "lodash";
import axios from "./utils/axios";
import router from "./utils/router";
import store from "./utils/store";
import ms from "./utils/ms";
import proj from "./utils/proj";
import dicts from "./utils/dicts";
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;
Vue.config.productionSourceMap = false;

/**
 * 实例扩展
 */
Vue.prototype.$evtBus = new Vue();
Vue.prototype.$axios = axios;
Vue.prototype.$ms = ms;
Vue.prototype.$proj = proj;
Vue.prototype.$dicts = dicts;
Vue.use(ElementUI);

/**
 * 动态安装组件
 */
Vue.prototype.$install = (name, options) => {
    if (!options) options = {};

    // var component = Vue.extend(Vue.component(name));
    var component = Vue.component(name);
    if (!component) {
        console.error("component", name, "not exists!");
        return;
    }
    var cmp = new component({
        router: router,
        store: store,
        propsData: options.props ? Object.assign({ visible: true }, options.props) : { visible: true },
    });
    if (options.events) {
        for (var p in options.events) {
            cmp.$on(p, options.events[p]);
        }
    }
    if (options.el) {
        cmp.$mount(options.el);
    } else {
        var render = document.createElement('div');
        document.body.appendChild(render);
        cmp.$mount(render);
    }
};

/**
 * 自动注册组件
 */
var components = require.context("./components", true, /\.vue$/);
loadComponents(components);

function loadComponents(components) {
    components.keys().forEach(fileName => {
        const cmp = components(fileName);

        var pos = fileName.lastIndexOf("/");
        var name = fileName.substr(pos + 1);
        var pos1 = name.lastIndexOf(".");
        if (pos1 > 0) {
            name = name.substr(0, pos1);
        }
        name = lodash.upperFirst(lodash.camelCase(name));

        Vue.component(name, cmp.default || cmp);
    });
}

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.screen.width < 500) {
    // 跳转到移动端域名
    window.location.href = 'http://kjuyt.cn:8082/official_website/app_index.html';
}
/**
 * 挂载DOM节点
 */
var vm = new Vue({
    router,
    store,
    render: h => h(App),
    // mounted() {
    //     document.dispatchEvent(new Event('render-event'))
    // }
}).$mount("#app");

export default vm;