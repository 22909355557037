<template>
    <el-dialog :visible.sync="visible" width="80%" :before-close="beforeClose">
        <webview :src="src"></webview>
    </el-dialog>
</template>


<script>
export default {
    props: {
        visible: false,
        src:''
    },
}
</script>