import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
    // 保存登录后的用户信息
    userinfo: {},

    // 左侧菜单折叠状态, 0: 未折叠
    menuFoldState: 0,

    // 页面位置
    locationText: '',

    // 强制刷新
    renderFlag: true,

    turboParam: {},

    audioParam: {}
};

const getters = {};

const mutations = {
    // 保存登录的用户信息
    userinfo(state, info) {
        state.userinfo = info;
    },

    // 菜单折叠状态
    menuFoldState(state, val) {
        state.menuFoldState = val;
    },

    // 页面位置文本
    locationText(state, val) {
        state.locationText = val;
    },

    // 强制刷新
    renderFlag(state, val) {
        state.renderFlag = val;
    },

    // 文案创作参数
    turboParam(state, val) {
        state.turboParam = val;
    },

    // 文案创作参数
    audioParam(state, val) {
        state.audioParam = val;
    }
};

const store = new Vuex.Store({
    state,
    getters,
    mutations
});

export default store;