import Vue from "vue";
import _this from "../main";
import $ from "jquery";

const proj = {
    // 基础地址
    getApiBaseUrl() {
        var baseurl = process.env.VUE_APP_API_BASE_URL;
        var natip = process.env.VUE_APP_API_NAT_IP;
        if (natip && natip != '' && window.location.href.indexOf(natip) >= 0) {
            baseurl = baseurl.replace(process.env.VUE_APP_NAT_REPLACE_KEY, natip);
        }
        return baseurl;
    },
};

export default proj;