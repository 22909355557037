<template>
    <el-dialog :visible.sync="visible" width="400px" :before-close="beforeClose">
        <div class="login-dialog-header flex-row just-between">
            <div :class="active == 1 ? 'active' : 'no-active'" @click="onWxLogin">微信登录</div>
            <div :class="active == 2 ? 'active' : 'no-active'" @click="onMobile">手机登录</div>
        </div>
        <div class="login-dialog-body" v-if="active == 2">
            <el-input placeholder="输入手机号" v-model="phone" clearable class="up-input">
            </el-input>
            <div class="flex-row down-input">
                <el-input placeholder="输入验证码" v-model="ncode" clearable style="margin-right: 10px;">

                </el-input>
                <el-button slot="append" @click="onSendCode" type="primary" :disabled="cnt > 0"
                    :style="cnt > 0 ? 'opacity: 0.7;' : ''">
                    <span v-if="cnt == 0">发送验证码</span>
                    <span v-else>{{ cnt }}秒后重新发送</span>
                </el-button>
                <!-- <div class="send-code-button" @click="onSendCode">
                    <span v-if="cnt == 0">发送验证码</span>
                    <span v-else>{{ cnt }}秒后重新发送</span>
                </div> -->
            </div>
            <div class="login-dialog-button" style="cursor: pointer;"
                :class="phone == '' || ncode == '' || !checked ? 'login-dialog-disable' : ''" @click="onLogin()">
                登录
            </div>
        </div>
        <div class="login-dialog-body flex-row just-between" v-if="active == 1">
            <div></div>
            <!-- <div class="qrcode">
                <img :src="qrcodeDataUrl" width="200" height="200">
            </div> -->
            <div id="wexin"></div>
            <div></div>
        </div>
        <div class="login-dialog-foot flex-row just-between" v-if="active == 2">
            <div></div>
            <el-checkbox v-model="checked" style="margin-right: 5px;"></el-checkbox>
            <div style="float:left">
                登录/注册即代表同意<a style="color: #0557E9;" :href="agreement" target="_blank">《用户协议》</a>和<a
                    style="color: #0557E9;" :href="privacyPolicy" target="_blank">《隐私政策》</a>
            </div>
            <div></div>
        </div>
    </el-dialog>
</template>

<script>
import QRCode from 'qrcode';
export default {
    data() {
        return {
            active: 1,
            checked: false,
            phone: '',
            ncode: '',
            cnt: 0,
            countdown: null,
            qrcodeDataUrl: '',
            qrcodeUrl: '',
            sceneStr: '',
            timer: null,
            agreement: "",
            privacyPolicy: ""
        };
    },
    props: {
        visible: false
    },
    created() {
        this.getSystemHTML()
        this.onWxLogin()
    },
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        onSendCode() {
            if (this.cnt != 0) {
                return
            }
            if (this.phone == '' || this.phone == null) {
                this.$message({
                    showClose: true,
                    message: '请输入手机号',
                    type: 'warning'
                });
                return
            }

            this.$axios.post("/web/consumer/getCms?phone=" + this.phone)
                .then(res => {
                    res = res.data
                    if (res.status != 200) {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'error'
                        })
                        return
                    }
                    this.createInterval()
                })
        },

        createInterval() {
            // 禁用按钮并开始倒计时
            this.cnt = 60
            this.countdown = setInterval(() => {
                this.cnt--
                if (this.cnt <= 0) {
                    clearInterval(this.countdown)
                }
            }, 1000)
        },

        onLogin() {
            if (this.phone == '' || this.phone == null) {
                this.$message({
                    showClose: true,
                    message: '请输入手机号',
                    type: 'warning'
                });
                return
            }
            if (this.ncode == '' || this.ncode == null) {
                this.$message({
                    showClose: true,
                    message: '请输入验证码',
                    type: 'warning'
                });
                return
            }
            if (!this.checked) {
                this.$message({
                    showClose: true,
                    message: '请勾选是否同意《用户协议》和《隐私政策》',
                    type: 'warning'
                });
                return
            }

            this.$axios.post("/web/consumer/loginCode?phone=" + this.phone + "&ncode=" + this.ncode)
                .then(res => {
                    res = res.data
                    if (res.status != 200) {
                        this.$message({
                            showClose: true,
                            message: res.msg,
                            type: 'error'
                        })
                        return
                    }
                    this.$store.commit("userinfo", res.data);
                    localStorage.setItem(process.env.VUE_APP_LOCAL_TOKEN_KEY, res.data.token);
                    this.$forceUpdate()
                    this.visible = false
                    // this.$router.go(0)
                })
        },

        onWxLogin() {
            clearInterval(this.timer)
            this.timer = null
            this.getWxLoginQr()
            // this.getWxGzQr()
            this.active = 1
        },

        getWxLoginQr() {
            let resp = Math.ceil(Math.random() * 10000032423423400);
            // var url = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxe3e0024b6e02f0e6'
            //     + '&scope=snsapi_login'
            //     + '&redirect_uri=' + encodeURIComponent(process.env.VUE_APP_CALLBACK_URL + "/web/loginCode")
            //     + '&response_type=code'
            //     + '&state=' + resp
            //     + '&login_type=jssdk'
            //     + '&style=black';
            // //   + '&self_redirect=' + this.self_redirect
            // //   + '&href=' + this.href;
            // console.log(url)
            // const qrCodeCanvas = document.createElement('canvas');
            // QRCode.toCanvas(qrCodeCanvas, url, { width: 200, height: 200 });
            // this.qrcodeDataUrl = qrCodeCanvas.toDataURL('image/png');
            // console.log(this.qrcodeDataUrl);
            const s = document.createElement("script");
            s.type = "text/javascript";
            s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
            const wxElement = document.body.appendChild(s);
            
            wxElement.onload = function () {
                new WxLogin({
                    id: "wexin", 
                    width: 100,
                    height: 100,
                    appid: "wxe3e0024b6e02f0e6", 
                    scope: "snsapi_login", 
                    redirect_uri: encodeURIComponent(
                        process.env.VUE_APP_CALLBACK_URL + "/web/loginCode"
                    ), 
                    self_redirect: true,
                    state: resp,
                    style: "black",
                    with: "100",
                    href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30='
                });
            };

            this.timer = window.setInterval(() => {
                // 要执行的函数
                this.wen(resp)
            }, 1000)
        },

        getWxGzQr() {
            this.$axios.post("/web/consumer/loginWithGzCode").then(res => {
                res = res.data
                if (res.status != 200) {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    })
                    return
                }

                this.qrcodeUrl = "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + res.data.ticket
                this.sceneStr = res.data.sceneStr
                this.timer = window.setInterval(() => {
                    // 要执行的函数
                    this.wxCheckGz()
                }, 1000)
            })
        },

        wxCheckGz() {
            this.$axios.post("/web/consumer/loginWithGzQuery?sceneStr=" + this.sceneStr, {}, { __noloading: true }).then(res => {
                res = res.data
                if (res.status == 1060) {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    })
                    clearInterval(this.timer)
                    this.timer = null
                }
                if (res.status == 200) {
                    this.$store.commit("userinfo", res.data);
                    localStorage.setItem(process.env.VUE_APP_LOCAL_TOKEN_KEY, res.data.token);
                    this.$forceUpdate()
                    clearInterval(this.timer)
                    this.timer = null
                    this.visible = false
                }
            })
        },

        wen(resp) {
            this.$axios.post("/web/checkLogin?state=" + resp, {}, { __noloading: true }).then(res => {
                res = res.data
                if (res.status == 1060) {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    })
                    clearInterval(this.timer)
                    this.timer = null
                }
                if (res.status == 200) {
                    this.$store.commit("userinfo", res.data);
                    localStorage.setItem(process.env.VUE_APP_LOCAL_TOKEN_KEY, res.data.token);
                    this.$forceUpdate()
                    clearInterval(this.timer)
                    this.timer = null
                    this.visible = false
                }
            })
        },

        beforeClose(done) {
            if(this.active == 1) {
                var doc = document.getElementById("wexin")
                doc.innerHTML = ""
                doc.remove()
            }
            clearInterval(this.timer)
            this.timer = null
            this.visible = false
            done()
        },

        getSystemHTML() {
            this.$axios.post("/web/getSystemHTML").then(res => {
                this.agreement = res.data.data.userAgreement
                this.privacyPolicy = res.data.data.privacyPolicy
            })
        },

        onMobile() {
            clearInterval(this.timer)
            this.timer = null
            if(this.active == 1) {
                var doc = document.getElementById("wexin")
                doc.innerHTML = ""
            }
            this.active = 2
        }
    }
};
</script>

<style lang="scss">
.el-dialog {
    margin-top: 10% !important;
}

.el-dialog__headerbtn {
    top: 15px !important;
    right: 15px !important;
}

.el-dialog__body {
    padding-top: 10px !important;
}


.login-dialog-header {
    margin-left: 50px;
    margin-right: 50px;
    font-family: Microsoft YaHei;

    .active {
        color: #0557E9;
        font-size: 20px;
        font-weight: 400;
        padding-bottom: 10px;
        border-bottom: #0557E9 solid 1px;
    }
}

@media (max-width: 1600px) {
    .login-dialog-header {
        margin-left: 30px;
        margin-right: 30px;
    }
}

.no-active {
    color: #010101;
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 11px;
}

.login-dialog-body {
    font-size: 14px;
    padding: 0 20px;

    .el-input--suffix .el-input__inner {
        border-radius: 20px;
        padding-right: 15px !important;
    }

    .up-input {
        margin-bottom: 30px;
        margin-top: 50px;
    }

    .down-input {
        margin-bottom: 30px;
    }

    .el-input-group--prepend .el-input__inner,
    .el-input-group__append {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-left: 0px;
        background-color: #FFF;
    }

    .qrcode {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
    border-color: #DCDFE6 !important;
}

// @media (max-width: 1600px) {
//     .login-dialog-body {
//         padding: 0 10px !important;
//     }
// }
.el-input-group__append button.el-button {
    border-color: transparent;
    background-color: transparent;
    color: inherit;
    border-top: 0;
    border-bottom: 0;
    padding-left: 0;
}

// .send-code-button{
//     height: 40px;
//     background-color: #0557E9;
//     text-align: center;
//     line-height: 40px;
//     color: #FFF;
// }
.el-button--primary {
    background-color: #0557E9 !important;
    border-color: #0557E9 !important;
}

.login-dialog-button {
    height: 40px;
    border-radius: 20px;
    background-color: #0557E9;
    text-align: center;
    line-height: 40px;
    color: #FFF;
    margin-bottom: 30px;

    &:active {
        opacity: 0.7;
    }
}

.login-dialog-disable {
    opacity: 0.5;

    &:active {
        opacity: 0.5;
    }
}

.login-dialog-foot {
    font-size: 14px;
    padding: auto;
}
</style>