<template>
    <el-dialog :visible.sync="visible" width="500px">
        <div class="group-item">
            <div class="content-box">
                <div class="c-hd">
                    <ul class="nav">
                        <li :class="active == 1 ? 'active' : ''" style="cursor: pointer;"><a
                                @click="active = 1">链接视频</a></li>
                        <li :class="active == 2 ? 'active' : ''" style="cursor: pointer;"><a
                                @click="active = 2">本地音视频</a></li>
                        <li :class="active == 3 ? 'active' : ''" style="cursor: pointer;"><a
                                @click="active = 3">本地图片</a></li>
                    </ul>
                </div>
                <div class="c-bd">
                    <div class="panel" v-if="active == 1">
                        <div class="edit-box" v-if="!converted">
                            <div class="edit-container">
                                <textarea name="content" v-model="videoUrl"
                                    placeholder="请粘贴短视频分享链接地址，支持各大主流平台视频提取"></textarea>
                                <div class="setting">
                                    <button class="J_clearContent btn btn-small btn-gray"
                                        @click="videoUrl = ''">清空</button>
                                    <!-- <button class="J_clearContent btn btn-small btn-gray"
                                                        style="margin-right: 15px;" @click="onPaste">粘贴</button> -->
                                </div>
                            </div>
                            <p class="text-gray">*支持MP4格式，50M以内，识别中英文</p>
                        </div>
                        <div class="view-box" v-if="converted">
                            <video controls="" name="media" ref="video" style="max-height: 300px;">
                                <source :src="convertData.shareVideoUrl" type="video/mp4">
                            </video>
                        </div>
                        <div class="operation">
                            <button class="btn btn-large btn-blue btn-submit" @click="onConvertLinks()"
                                v-if="!converted">一键提取文案</button>
                            <button class="btn btn-large btn-blue btn-submit" v-if="converted"
                                @click="converted = false">重新粘贴链接</button>
                        </div>
                    </div>
                    <div class="panel" v-if="active == 2">
                        <div class="upload-box">
                            <div class="flex-row just-between view-box posir" v-if="uploadVideoUrl || uploadAudioUrl">
                                <div></div>
                                <video controls="" name="media" ref="uploadVideo" v-if="uploadVideoUrl" style="max-height: 300px;">
                                    <source :src="uploadVideoUrl" type="video/mp4">
                                </video>
                                <audio controls="" name="media" ref="uploadAudio" v-if="uploadAudioUrl">
                                    <source :src="uploadAudioUrl" type="audio/mp3">
                                </audio>
                                <div></div>
                                <i class="el-icon-close close-icon" v-if="uploadVideoUrl || uploadAudioUrl"
                                    @click="clearMedia"></i>
                            </div>
                            <label class="upload" v-if="!uploadAudioUrl && !uploadVideoUrl">
                                <input type="file" @change="onChangeFile" id="upload-file" name="upload"
                                    accept=".mp3,.mp4" />
                                <span class="title">上传音视频</span>
                                <span class="text-gray">支持MP4、MP3格式，50M以内，识别中英文</span>
                            </label>
                        </div>
                        <div class="operation">
                            <button class="btn btn-large btn-blue btn-submit" @click="onConvertFile()">一键提取文案</button>
                        </div>
                    </div>
                    <div class="panel" v-if="active == 3">
                        <div class="upload-box">
                            <div class="flex-row just-between posir" v-if="imageUrl">
                                <div></div>
                                <img :src="imageUrl" style="max-height: 300px;max-width: 420px;">
                                <div></div>
                                <i class="el-icon-close close-icon" @click="imageUrl = ''"></i>
                            </div>
                            <label class="upload" v-if="!imageUrl">
                                <input id="upload-pic" @change="onChangePic" type="file" name="upload" value=""
                                    accept=".png,.jpg,.bmp" />
                                <span class="title">上传图片</span>
                                <span class="text-gray">支持PNG/JPG/BMP格式，4M以内，识别中英文</span>
                            </label>
                        </div>
                        <div class="operation">
                            <button class="btn btn-large btn-blue btn-submit" @click="onConvertPic()">一键提取文案</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            active: 1,
            videoUrl: '',
            converted: false,
            uploadVideoUrl: '',
            uploadAudioUrl: '',
            imageUrl: '',
            picFile: null,
        }
    },

    props: {
        visible: false
    },

    methods: {
        onConvertLinks() {
            if (!localStorage.token || localStorage.token == 'null') {
                this.$install('LoginDialog')
                return false
            }

            this.$axios.post("/web/ConvertLinks?token=" + localStorage.token + "&dyUrl=" + encodeURIComponent(this.videoUrl), {}, { text: "提取中，请耐心等待..." }).then(res => {
                res = res.data
                if (res.status == 501) {
                    this.$confirm("您的试用次数已用完，请前往开通VIP会员", "提示", {
                        confirmButtonText: '去开通',
                        cancelButtonText: '取消',
                        center: true
                    }).then(() => {
                        this.$router.push("/vip")
                    }).catch(() => {
                    })
                    return
                }
                if (res.status != 200) {
                    this.$message.error(res.msg)
                    return
                }
                this.convertData = res.data
                this.converted = true

                this.onGetAudio()
            })
        },

        onGetAudio() {
            if (!localStorage.token || localStorage.token == 'null') {
                this.$install('LoginDialog')
                return false
            }
            this.$axios.post("/web/audioExtractionUrl?token=" + localStorage.token + "&url=" + encodeURIComponent(this.convertData.dyUrl), {}, { text: "提取中，请耐心等待..." }).then(res => {
                res = res.data
                if (res.status == 501) {
                    this.$confirm("您的试用次数已用完，请前往开通VIP会员", "提示", {
                        confirmButtonText: '去开通',
                        cancelButtonText: '取消',
                        center: true
                    }).then(() => {
                        this.$router.push("/vip")
                    }).catch(() => {
                    })
                    return
                }
                if (res.status != 200) {
                    this.$message.error(res.msg)
                    return
                }
                console.log(res)
                this.hid = res.data
                this.getAudioExtractionQuery()
            })
        },

        getAudioExtractionQuery() {
            this.$axios.post("/web/audioExtractionQuery?hid=" + this.hid, {}, { text: "提取中，请耐心等待..." }).then(res => {
                res = res.data
                if (res.status != 200) {
                    this.$message.error(res.msg)
                    return
                }

                if (res.data != null) {
                    var aiText = res.data
                    if (aiText == '') {
                        aiText = "字幕提取失败，未检测到视频内有人声解说。"
                    } else {
                        this.$message.success("提取成功")
                    }
                    this.$emit("afterExtract", aiText)
                    this.visible = false
                }
                else {
                    setTimeout(this.getAudioExtractionQuery(), 1000)
                }
            })
        },

        onChangeFile(event) {
            console.log(event);
            var input = event.target;
            var file = input.files[0];
            if (file.type == 'video/mp4') {
                this.fileType = 'mp4'
                this.uploadFile = file
                this.uploadAudioUrl = ''
                this.uploadVideoUrl = URL.createObjectURL(file);
                this.$message.success("视频上传成功，请点击提取")
            }
            if (file.type == 'audio/mpeg') {
                this.fileType = 'mp3'
                this.uploadFile = file
                this.uploadVideoUrl = ''
                this.uploadAudioUrl = URL.createObjectURL(file);
                this.$message.success("音频上传成功，请点击提取")
            }
        },

        onConvertFile() {
            if (!this.fileType) {
                return
            }
            if (this.uploadFile.size > 50 * 1024 * 1024) {
                this.$message.error("视频不得超过50M")
                return
            }
            var formData = new FormData();
            formData.append('file', this.uploadFile);
            if (!localStorage.token || localStorage.token == 'null') {
                this.$install('LoginDialog')
                return
            }
            this.$axios.post("/web/fileExtraction?token=" + localStorage.token + "&type=" + this.fileType, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                text: "提取中，请耐心等待..."
            })
                .then(res => {
                    res = res.data
                    if (res.status == 501) {
                        this.$confirm("您的试用次数已用完，请前往开通VIP会员", "提示", {
                            confirmButtonText: '去开通',
                            cancelButtonText: '取消',
                            center: true
                        }).then(() => {
                            this.$router.push("/vip")
                        }).catch(() => {
                        })
                        return
                    }
                    if (res.status != 200) {
                        this.$message.error(res.msg)
                        return
                    }
                    this.hid = res.data
                    this.getAudioExtractionQuery()
                })
        },

        clearMedia() {
            this.uploadVideoUrl = ''
            this.uploadAudioUrl = ''
        },

        onChangePic(event) {
            const input = event.target;
            if (!input.files || !input.files[0]) {
                return;
            }

            const file = input.files[0];
            this.picFile = file
            this.imageUrl = URL.createObjectURL(file);
        },

        onConvertPic() {
            var reader = new FileReader()
            reader.readAsDataURL(this.picFile)
            reader.onload = () => {
                console.log(reader.result)
                if (!localStorage.token || localStorage.token == 'null') {
                    this.$install('LoginDialog')
                    return
                }
                this.$axios.postjson("/web/getBaiduTokenPic", {
                    token: localStorage.token,
                    image: reader.result
                }, { text: "提取中，请耐心等待..." }).then(res => {
                    res = res.data
                    if (res.status == 501) {
                        this.$confirm("您的试用次数已用完，请前往开通VIP会员", "提示", {
                            confirmButtonText: '去开通',
                            cancelButtonText: '取消',
                            center: true
                        }).then(() => {
                            this.$router.push("/vip")
                        }).catch(() => {
                        })
                        return
                    }
                    if (res.status != 200) {
                        this.$message.error(res.msg)
                        return
                    }
                    this.$emit("afterExtract", res.data)
                    this.visible = false
                })
            }

        },
    }
}
</script>

<style lang="scss" scoped>
.c-hd {
    padding: 10px 20px 0;
    margin-bottom: 10px;

    ul {
        display: flex;
        background: rgba(0, 0, 0, 0.015);
        border-bottom: 1px solid rgba(0, 0, 0, 0.035);
    }

    li {
        flex: 1;
        min-width: 0;

        a {
            display: block;
            line-height: 40px;
            text-align: center;
            font-size: 15px;
            color: #666;
        }

        &.active {
            a {
                position: relative;
                font-weight: bold;
                color: #333;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 16px;
                    height: 5px;
                    content: '';
                    border-radius: 3px;
                    margin-left: -8px;
                    background: #5c79fa;
                }
            }
        }
    }
}

.edit-box {
    margin-bottom: 20px;

    .edit-container {
        background: #fff;
        border: 1px solid #f0f0f0;
        border-radius: 5px;
        overflow: hidden;
    }

    textarea {
        display: block;
        width: 100%;
        height: 160px;
        padding: 10px 15px;
        border: medium none;
        line-height: 22px;
        outline: none;
        box-sizing: border-box;
        white-space: pre-line;
        resize: none;
        overflow: auto;
    }

    p {
        line-height: 30px;
    }

    .setting {
        padding: 10px 15px;
        line-height: 32px;
        overflow: hidden;

        .btn {
            float: right;
        }
    }
}

.view-box {
    margin-bottom: 25px;
    text-align: center;

    video {
        display: block;
        width: 100%;
    }
}

.btn {
    position: relative;
    display: inline-block;
    min-width: 60px;
    height: 36px;
    padding: 0 16px;
    background: #5c79fa;
    border: none medium;
    line-height: 34px;
    vertical-align: middle;
    text-align: center;
    font-family: Tahoma, Arial, Helvetica, "微软雅黑";
    font-size: 15px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: content-box;
    outline: none
}

.btn-small {
    display: inline-block;
    min-width: 48px;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    font-size: 14px
}

.btn-gray {
    background: #eee;
    color: #333
}

.btn-gray:hover {
    background: #ddd
}

.btn-large {
    display: inline-block;
    min-width: 72px;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 16px
}

.btn-submit {
    min-width: 300px !important;
    margin-left: auto;
    margin-right: auto;
}

.operation {
    text-align: center;

    .btn {
        margin: 0 5px;
        border-radius: 20px;
    }
}

.text-gray {
    color: #999999
}

.posir {
    position: relative;

    .close-icon {
        position: absolute;
        font-size: 24px;
        top: 0;
        right: 0;
        cursor: pointer;
    }
}

.upload-box {
    margin-bottom: 20px;
    padding: 20px;
    padding-top: 50px;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    height: 172px;

    .upload {
        display: block;
        padding-top: 60px;
        background: url('../../assets/image/writing/icon-upload.png') center 5px/ 64px 64px no-repeat;
        cursor: pointer;

        input[type="file"] {
            display: none;
        }

        span {
            display: block;
            line-height: 25px;
            text-align: center;

            &.title {
                font-size: 16px;
            }
        }
    }
}
</style>