<template>
  <div id="app">

    <router-view></router-view>
  </div>
</template>

<script>
// import elHacker from "./utils/elHacker";

export default {
};
</script>

<style lang="scss">
@import url(./sass/_yy.scss);



.el-breadcrumb__inner a {
  cursor: pointer !important;
}

.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  font-weight: normal !important;
}

* {
  scrollbar-width: thin;
}
</style>
