<template>
    <el-dialog :visible.sync="visible" width="320px" :beforeClose="beforeClose">
        <div class="flex-row just-between">
            <div></div>
            <div class="ned" v-if="payType == 2">
                <div class="dig">
                    <iframe class="int" :src="this.action" frameborder="0">
                    </iframe>
                    <form name="submit_form" id="fort" method="post" :action=this.action>
                        <input type="submit" value="提交" id="list" style="display:none">
                    </form>
                    <div class="zfb"></div>
                    <div class="sasa">打开支付宝</div>
                    <div class="sast">扫一扫完成支付</div>
                </div>
            </div>
            <div class="neds flex-row" v-if="payType == 1">
                <div class="wx"></div>
                 <div class="flex"></div>
                <div>
                    <img :src="qrcodeDataUrl">
                    <div id="qrCodeDiv"></div>
                    <div class="sasa" style="text-align: center;">打开微信</div>
                    <div class="sast" style="text-align: center;">扫一扫完成支付</div>
                </div>
                <div class="flex"></div>
            </div>
            <div class="neds" v-if="payType == 3">
                <div class=" flex-row just-between">
                    <div></div>
                    <div class="paySuccess">
                        <i class="el-icon-check" style="font-size: 50px;margin-top: 30px;color: black;"></i>
                    </div>
                    <div></div>
                </div>
                <div class="sast" style="text-align: center;margin-top: 30px;">支付完成<span v-if="cnt > 0">({{ cnt }}s)</span></div>
            </div>
            <div></div>
        </div>
    </el-dialog>
</template>

<script>
import QRCode from 'qrcode';
export default {
    data() {
        return {
            text: '',
            codeid: '',
            action: "",
            timer: null,
            qrcodeDataUrl: '',
            cnt:0,
            countdown:null
        };
    },
    props: {
        visible: false,
        payType: null,
        productId: null,
        task: null,
    },
    created() {
        console.log(this.payType, this.productId);
        if (this.payType == 1) {
            this.getWxCode()
        }
        if (this.payType == 2) {
            this.getAliCode()
        }
    },
    
    beforeDestroy() {
        clearInterval(this.timer)
        clearInterval(this.countdown)
    },

    methods: {
        bindQRCode() {
            // document.getElementById('qrCodeDiv').innerHTML = '';
            // new QRCode('qrCodeDiv', {
            //     text: this.text,
            //     width: 199,
            //     height: 200,
            //     colorDark: "#333333", //二维码颜色
            //     // colorLight: "#ffffff", //二维码背景色
            //     correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
            // })
            console.log(this.text)
            let qrCodeCanvas = document.createElement('canvas');
            QRCode.toCanvas(qrCodeCanvas, this.text, { width: 200, height: 200 });
            this.qrcodeDataUrl = qrCodeCanvas.toDataURL('image/png');
        },
        getWxCode() {
            this.$axios.post("/web/rechargeWechat?token=" + localStorage.token +
                "&productId=" + this.productId +
                (this.task ? ("&task=" + this.task) : "") +
                "&channelCode=2&door=微信_PC版").then(res => {
                    res = res.data
                    if (res.status != 200) {
                        this.$message.error(res.msg)
                        return
                    }
                    this.codeid = res.data.orderId
                    this.text = res.data.codeUrl
                    this.bindQRCode();
                    this.timer = setInterval(() => {
                        this.queryPayType()
                    }, 2000)
                })
        },

        getAliCode() {
            this.$axios.post("/web/rechargeAli?token=" + localStorage.token +
                "&productId=" + this.productId +
                (this.task ? ("&task=" + this.task) : "") +
                "&channelCode=2&door=支付宝_PC版").then(res => {
                    res = res.data
                    if (res.status != 200) {
                        this.$message.error(res.msg)
                        return
                    }
                    this.codeid = res.data.orderId
                    this.action = "https://openapi.alipay.com/gateway.do?" + res.data.aliPay;
                    this.timer = setInterval(() => {
                        this.queryPayType()
                    }, 2000)
                })
        },

        queryPayType() {
            this.$axios.post("/web/rechargeNotifySel?token=" + localStorage.token + "&order_no=" + this.codeid, {}, { __noloading: true })
                .then(res => {
                    res = res.data
                    if (res.status == 200) {
                        if(this.payType == 3) return
                        this.payType = 3
                        this.timer = null
                        clearInterval(this.timer)
                        this.cnt = 2
                        this.countdown = setInterval(() => {
                            this.cnt--
                            console.log("cnt===>" + this.cnt)
                            if (this.cnt < 0) {
                                clearInterval(this.countdown)
                                history.go(-1)
                                this.visible = false
                            }
                        }, 1000)
                    }
                })
        },

        beforeClose(done) {
            this.timer = null
            if (this.payType == 3) {
                history.go(-1)
                // history.go(0)
            }
            done()
        }
    }
}
</script>

<style lang="scss">
.dig {
    // height: 330px;
    width: 100%;
    // background-color: #9bf5f5;
    position: relative;
    text-align: center;
}

.neds {
    width: 100%;
    position: relative;

}

.int {
    height: 206px;
    width: 204px;
    padding: 5px;
}

// .sasa {
//     font-family: Microsoft YaHei;
//     font-weight: 400;
//     color: #666666;
//     text-align: center;

// }

// .sast {
//     font-family: Microsoft YaHei;
//     font-weight: 400;
//     color: #666666;
//     text-align: center;
// }
</style>