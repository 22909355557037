import axios from "axios";
import qs from "qs";
import { Loading } from "element-ui";
import Vue from "vue";
import $ from "jquery";
import proj from "./proj";

Vue.use(Loading);

var loadingInstance = null;
var loadingCount = 0;

function showLoading(text) {
    loadingCount++;
    if (!text) {
        text = ''
    }
    if (loadingInstance == null) {
        loadingInstance = Loading.service({
            body: false,
            lock: false,
            background: "rgba(0, 0, 0, 0.3)",
            spinner: "el-icon-loading",
            customClass: "large-spinner",
            text: text
        });
    }
}

function hideLoading() {
    loadingCount--;
    if (loadingCount <= 0) {
        setTimeout(() => {
            if (loadingInstance && loadingCount <= 0) {
                loadingInstance.close();
                loadingInstance = null;
            }
        }, 100);
    }
}

axios.showLoading = showLoading
axios.hideLoading = hideLoading

axios.postjson = (uri, params, config) => {
    if (!params) {
        params = {};
    }
    if (!config) {
        config = {};
    }

    return axios.post(
        uri,
        params,
        $.extend(config, {
            headers: { "Content-Type": "application/json;charset=utf-8" }
        })
    );
};

axios.interceptors.request.use(
    config => {
        let text = ''
        if (config.text) {
            text = config.text
        }
        if (!config.__noloading) {
            showLoading(text);
        }

        // config.headers.common["token"] = localStorage.getItem(process.env.VUE_APP_LOCAL_TOKEN_KEY);
        if (config.url.toLowerCase().indexOf("http://") < 0 && config.url.toLowerCase().indexOf("https://") < 0) {
            config.baseURL = proj.getApiBaseUrl();
            // config.baseURL = "/api";
        }

        var exceptUris = [];

        if (config.method == "post") {
            if (
                config.headers["Content-Type"] &&
                config.headers["Content-Type"].toLowerCase() ==
                "application/json;charset=utf-8"
            ) {
                return config;
            }

            for (var i = 0; i < exceptUris.length; i++) {
                if (config.url.indexOf(exceptUris[i]) >= 0) {
                    // 默认使用json
                    config.headers["Content-Type"] = "application/json;charset=UTF-8";
                    return config;
                }
            }

            // 使用参数
            if (config.data && config.data != "") {
                if (!config.headers["Content-Type"]) {
                    config.headers["Content-Type"] =
                        "application/x-www-form-urlencoded;charset=UTF-8";
                    config.data = qs.stringify(config.data);
                }

                return config;
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (!response.config.__noloading) {
            hideLoading();
        }

        return response;
    },
    error => {
        var res = {
            data: {
                error: -1,
                message: error.message
            }
        };

        if (error.config && !error.config.__noloading) {
            hideLoading();
        }

        return res;
    }
);

export default axios;